import * as yup from 'yup';
import { REGEXP_DISPLAY_NAME } from '@app/constants/validation';
const validationSchema = (translateFn) => yup.object().shape({
    name: yup
        .string()
        .max(30, '')
        .matches(new RegExp(REGEXP_DISPLAY_NAME), translateFn('profile.messages.publicname_only_latin_and_numbers'))
        .required(),
    parent_exchange_id: yup
        .number()
        .required(),
    monthly_following_fee: yup
        .number()
        .nonNullable()
        .min(0, 'monthly following fee should be greater than 0')
        .required(),
});
export default validationSchema;
