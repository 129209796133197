import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { ContainerRow } from '@components/styled';
import ExchangeIcon from '@shared/ui/icons/ExchangeIcon';
import { Body, BodySemiBold } from '@components/styled/Typography';
const Container = styled(ContainerRow)(() => ({
    width: '100%',
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
}));
const InfoContainer = styled(ContainerRow)((props) => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.s,
}));
const WalletSelectItem = ({ exchange, }) => {
    const theme = useTheme();
    return (_jsxs(Container, { children: [_jsxs(InfoContainer, { children: [_jsx(ExchangeIcon, { type: 'BINANCE' }), _jsx(BodySemiBold, { children: exchange.properties.connectionName }), _jsx(Body, { color: theme.palette.text.secondary, children: exchange.type.toUpperCase() })] }), _jsx(BodySemiBold, { children: `$ PLACEHOLDER: ${exchange.id}` })] }));
};
export default WalletSelectItem;
